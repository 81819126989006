import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import HeliosLogo from "./helio-logo.png";
import LangoneLogo from "./langone.png";
import "./WhatClientsSay.scss";

const WhatClientsSay = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <section className="py-5 ">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">
                        Testimonials
                    </h2>
                    <HorizontalLine center={true} className="mb-3" />
                    <div className="row">
                        <div className="col-md-12 offset-md-0 p-md-5">
                            <Carousel
                                activeIndex={index}
                                onSelect={handleSelect}
                                slide={true}
                                pause="hover"
                                interval="3500"
                                controls={true}
                                indicators={true}
                            >
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4  mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center ">
                                            <div className="col-md-8">
                                                <div class="testimonial">
                                                    <blockquote className="text-justify">
                                                        "Myelin-H is rapidly
                                                        advancing towards the
                                                        solution for remote
                                                        monitoring of real-time
                                                        change in the many
                                                        aspects of neurological
                                                        functioning that can be
                                                        affected by the often
                                                        devastating multiple
                                                        sclerosis disease. This
                                                        technology would be of
                                                        high value both
                                                        clinically and for
                                                        research application.{" "}
                                                        <br /> <br /> I believe
                                                        that their advances are
                                                        of significant clinical
                                                        value towards addressing
                                                        the unmet needs for our
                                                        patients."
                                                    </blockquote>
                                                    <p class="author">
                                                        Leigh Charvet, Director
                                                        and Professor of
                                                        Neurology
                                                        <span>
                                                            NYU Langone Health
                                                        </span>
                                                    </p>
                                                </div>
                                                {/* <p>
                                                "We believe that Myelin-H technology is of significant clinical value and is addressing unmet clinical needs with regards to the remote real-time monitoring of Parkinson's disease in the elderly. As a result, we are happy to collaborate with Myelin-H on their clinical trials, and we would be willing to consider purchasing Myelin-H proposed solution for our patients, once it is medically certified."
                                                </p> */}
                                            </div>
                                            <div className="col-md-4 mt-4">
                                                <a
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href="https://nyulangone.org/"
                                                >
                                                    <img
                                                        src={LangoneLogo}
                                                        alt=""
                                                        className="img-fluid mb-0 mb-md-5"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className=" carousel-item-block p-4 mb-5 mb-md-0">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-md-8">
                                                <div class="testimonial">
                                                    <blockquote className="text-justify ">
                                                        "We believe that
                                                        Myelin-H technology
                                                        might be of significant
                                                        clinical value and is
                                                        addressing unmet
                                                        clinical needs with
                                                        regard to the remote
                                                        real-time monitoring of
                                                        multiple sclerosis. As a
                                                        result, we are happy to
                                                        collaborate with
                                                        Myelin-H on their
                                                        clinical trials, and we
                                                        would be willing to
                                                        consider purchasing
                                                        Myelin-H's proposed
                                                        solution for our
                                                        patients." <br />
                                                    </blockquote>
                                                    <p class="author">
                                                        Helios Park Clinic
                                                        Leipzig
                                                        <span>
                                                            Akademisches
                                                            Lehrkrankenhaus der
                                                            Universität Leipzig
                                                        </span>
                                                    </p>
                                                </div>
                                                {/* <p>
                                                "We believe that Myelin-H technology is of significant clinical value and is addressing unmet clinical needs with regards to the remote real-time monitoring of Parkinson's disease in the elderly. As a result, we are happy to collaborate with Myelin-H on their clinical trials, and we would be willing to consider purchasing Myelin-H proposed solution for our patients, once it is medically certified."
                                                </p> */}
                                            </div>
                                            <div className="col-md-4 mt-4">
                                                <a
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    href="https://www.helios-gesundheit.de/kliniken/leipzig-park-klinikum/"
                                                >
                                                    <img
                                                        src={HeliosLogo}
                                                        alt=""
                                                        className="img-fluid mb-0 mb-md-5"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhatClientsSay;
