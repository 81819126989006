import { Row, Spinner } from "react-bootstrap";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import YouTubeResponsiveEmbed from "../../../Components/YouTubeResponsiveEmbed";
import { advisoryData } from "./advisoryData";
import ProfileCard from "./ProfileCard";

import "./TeamAndAdvisory.scss";
import { teamData } from "./teamData";
import technicalTeam from "./technicalTeam";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
    advisorsCollectionRef,
    myelinersCollectionRef,
} from "../../../firebase/fire-app";
import { useEffect, useState } from "react";
import CompTechTeamSection from "./CompTechTeamSection";

const TeamAndAdvisory = () => {
    const [leadershipTeam, setLeadershipTeam] = useState([]);

    const [myelinAdvisors, loading, error] = useCollectionData(
        advisorsCollectionRef.orderBy("serial", "asc"),
        { idField: "id" }
    );
    const [myelinersList, myelinLoading, myelinError] = useCollectionData(
        myelinersCollectionRef
            .where("myelinLive", "==", true)
            .orderBy("serial", "asc"),
        { idField: "id" }
    );

    const [allMyelinners, mLoading, mError] = useCollectionData(
        myelinersCollectionRef.orderBy("serial", "asc"),
        { idField: "id" }
    );

    useEffect(() => {
        if (allMyelinners && allMyelinners.length > 0) {
            let listLeadership = allMyelinners.filter(
                (item) => item.mainWebCategory === "leadershipTeam"
            );

            setLeadershipTeam([...listLeadership, ...teamData]);
        }
    }, [allMyelinners]);
    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">
                        Meet our Myelinners
                    </h2>
                    <HorizontalLine center="true" className="mb-" />

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <p className="font-22 text-center">
                                Myelin-H's team includes engineers, scientists,
                                and business professionals led by world-class
                                executives and board members.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="h2 mb-4 text-center section-heading text-primary">
                        Leadership Team
                    </h2>
                    <HorizontalLine center="true" className="mb-" />

                    <div className="row d-flex justify-content-center">
                        {!leadershipTeam && (
                            <div className="text-center">
                                <Spinner animation="grow" />{" "}
                            </div>
                        )}
                        {leadershipTeam &&
                            leadershipTeam.length > 0 &&
                            leadershipTeam.map((item) => (
                                <ProfileCard
                                    info={item}
                                    type="leadership"
                                    col="3"
                                />
                            ))}
                    </div>
                </div>
            </section>
            <section className="py-5  bg-light">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">
                        Advisory Board
                    </h2>
                    <HorizontalLine center="true" className="mb-" />
                    {advisoryData && advisoryData.length > 0 && (
                        <div className="row d-flex justify-content-center">
                            {advisoryData &&
                                advisoryData.length > 0 &&
                                advisoryData.map((item) => (
                                    <ProfileCard info={item} col="3" />
                                ))}
                        </div>
                    )}
                </div>
            </section>

            <CompTechTeamSection/>

            
        </>
    );
};

export default TeamAndAdvisory;
