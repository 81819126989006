import { Image } from "react-bootstrap";
import HorizontalLine from "../../Components/common/HorizontalLine/HorizontalLine";
import { GreenBrandLogo } from "../../Images";
import Newsletter from "../Home/Newsletter/Newsletter";
import careerOpenings from "./career-data";
import CareerCard from "./CareerCard";
import CareerHero from "./CareerHero/CareerHero";
import { jobPostsCollectionRef } from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";

// import PDFViewer from 'pdf-viewer-reactjs'

const ViewCareers = () => {
    document.title = "Myelin-H | Careers";
    const openingLink1 = `https://www.euraxess.lu/jobs/hosting/ref-lximsca43-postdoc-candidate-msca-pf-biochemistry-molecular-biology-bioinformatics`;
    const [allPosts, loading, error] = useCollectionData(
        jobPostsCollectionRef
            .orderBy("createdAt", "desc")
            .where("isVisible", "==", true),
        { idField: "id" }
    );
    jobPostsCollectionRef
        .orderBy("createdAt", "desc")
        .where("isVisible", "==", true)
        .get();
    return (
        <>
            <CareerHero />
            <section className="bg-light py-5">
                <div className="container text-center">
                    <h3 className="text-center ">
                        Myelin-H is continuously recruiting. <br />
                        If you are excited to work at the intersection of
                        neuroscience, artificial intelligence, bio-sensing, and
                        space, please send us your CV and a few lines about
                        yourself.
                    </h3>
                    <p className="mt-4 font-22">
                        To apply, please send your CV to:
                    </p>
                    <a
                        className="btn btn-dark font-22 mt-3"
                        href="mailto:careers@myelinh.com"
                    >
                        careers@myelinh.com
                    </a>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading">
                        Current Openings
                    </h2>
                    <HorizontalLine center="true" className="mb-3" />

                    {/* <div className="row mt-5">
                        <div className="col-md-8 offset-md-2 text-center">
                            <p className="font-20">
                                Myelin-H is looking to host a <b> postdoctoral fellow</b> within the <b>Marie Skłodowska-Curie Postdoctoral Fellowship program</b>. More information can be found here: <br />
                                <a href={openingLink1} target="_blank" className="btn btn-primary mt-4">Read more...</a>
                            </p>
                        </div>
                    </div> */}
                </div>
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        {allPosts &&
                            allPosts.map((item) => (
                                <CareerCard col="5" data={item} key={item.id} />
                            ))}
                        {/* <CareerCard col="4" data={careerOpenings[0]} />
                        <CareerCard col="4" data={careerOpenings[1]} />
                        <CareerCard col="4" data={careerOpenings[2]} /> */}
                        {/* <CareerCard col="3" data={careerOpenings[2]}/>
                        <CareerCard col="3" data={careerOpenings[3]}/> */}
                    </div>
                </div>
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 offset-md-3">
                            <div className="m-5">
                                <Image
                                    src={GreenBrandLogo}
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Newsletter />
        </>
    );
};

export default ViewCareers;
