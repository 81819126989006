import { Spinner } from "react-bootstrap";
import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import ProfileCard from "./ProfileCard";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
import "./TeamAndAdvisory.scss";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
    advisorsCollectionRef,
    myelinersCollectionRef,
} from "../../../firebase/fire-app";

const CompTechTeamSection = () => {
    const [myelinersList, myelinLoading, myelinError] = useCollectionData(
        myelinersCollectionRef
            .where("myelinLive", "==", true)
            .orderBy("serial", "asc"),
        { idField: "id" }
    );

    const [allMyelinners, mLoading, mError] = useCollectionData(
        myelinersCollectionRef.orderBy("serial", "asc"),
        { idField: "id" }
    );
    const [index, setIndex] = useState(0);
    const [indexTwo, setIndexTwo] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handleSelect2 = (selectedIndex, e) => {
        setIndexTwo(selectedIndex);
    };


    return (
        <>
            <section className="py-5">
                <div className="container">
                    <h2 className="h2 text-center section-heading text-primary">
                        Technical Team
                    </h2>
                    <HorizontalLine center="true" className="mb-" />
                    {!myelinersList && (
                        <div className="text-center">
                            <Spinner animation="grow" />{" "}
                        </div>
                    )}
                    {/* <div className="row">
                        {myelinersList &&
                            myelinersList.length > 0 &&
                            myelinersList.map((item) => (
                                <ProfileCard type="tech" info={item} col="3" />
                            ))}
                    </div> */}

                    {!myelinLoading && myelinersList.length > 0 && (
                        <div className="row">
                            <div className="col-md-12 offset-md-0 p-md-5">
                                <Carousel
                                    activeIndex={index}
                                    onSelect={handleSelect}
                                    slide={true}
                                    pause="hover"
                                    interval="3500"
                                    controls={true}
                                    indicators={true}
                                    className="py-4"
                                >
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[0]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[1]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[2]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[3]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[4]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[5]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[6]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[7]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                </Carousel>

                                <Carousel
                                    activeIndex={indexTwo}
                                    onSelect={handleSelect2}
                                    slide={true}
                                    pause="hover"
                                    interval="3500"
                                    controls={true}
                                    indicators={true}
                                    className="py-4"
                                >
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[8]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[9]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[10]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[11]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className="">
                                        <div className="row">
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[12]}
                                            />
                                            <ProfileCard
                                                type="tech"
                                                info={myelinersList[13]}
                                            />
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default CompTechTeamSection;
